import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { toast } from 'react-toastify';
import { useQuery, useMutation } from '@tanstack/react-query';
import { fetchCategoriesAndTags, fetchPodcasts, fetchPodcastsbyId, languageOfpodcast, updatePodcastApi } from '../../http/Post';
import { useNavigate, useParams } from 'react-router';
import DatePicker from "react-datepicker";
import Dropzone from 'react-dropzone';
import { RSelect } from '../../components/Component';
import { queryClient } from '../../App';
import { Button, Spinner } from 'reactstrap';


const UpdatePodcastnew = () => {
    const { id } = useParams();  // Get podcast ID from URL params
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        image: null,
        image_link: null,
        explicit: '',
        podcasterName: '',
        language: 'English',
        releaseDate: '',
        tagsId: '',
        categoryId: '',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [files2, setFiles2] = useState([]);

    // Fetch Podcast Data
    const { data: podcastData, error: podcastError, isLoading: podcastLoading, isSuccess } = useQuery({
        queryKey: ['podcast', id],
        queryFn: () => fetchPodcastsbyId({ id }),
    });

    // const { data: categoriesTagsData, isError: isCategoriesError, error: categoriesError, isLoading: isFetchingCategories } = useQuery({
    //     queryKey: ['categories-and-tags'],
    //     queryFn: fetchCategoriesAndTags,
    // });
    const [categoryId, setCategoryId] = useState([]);

    const { data: categoriesTagsData, error, isError, isLoading: isFetching } = useQuery({
        queryKey: ['categories-and-tags', categoryId],
        queryFn: () => fetchCategoriesAndTags(categoryId),
    });

    console.log(`Categories :: ${categoriesTagsData}`)


    // Fetch languages
    const { data: languagesData, isError: isLanguagesError, error: languagesError, isLoading: isFetchingLanguages } = useQuery({
        queryKey: ['language'],
        queryFn: languageOfpodcast,
    });



    useEffect(() => {
        if (isSuccess) {
            setFormData({
                title: podcastData?.data?.podcastdata?.title || '',
                description: podcastData?.data?.podcastdata?.description || '',
                explicit: podcastData?.data?.podcastdata?.explicit || '',
                publisher: podcastData?.data?.podcastdata?.publisher || '',
                language: podcastData?.data?.podcastdata?.language || 'English',
                releaseDate: podcastData?.data?.podcastdata?.release_date || '',
                tagsId: podcastData?.data?.podcastdata?.tags_id || '',
                categoryId: podcastData?.data?.podcastdata?.category_id || '',
                image: podcastData?.data?.podcastdata?.image_url || '',
                image_link: podcastData?.data?.podcastdata?.image_link || '',
            }); // Store the response when data fetch is successful
        }
    }, [isSuccess, podcastData]);
    const navigate = useNavigate()

    // Mutation for updating podcast
    const updateMutation = useMutation({
        mutationFn: ({ updatedData, id }) => updatePodcastApi({ updatedData, id }), // Pass data and id correctly
        onSuccess: (data) => {
            // toast.success(data.message);
            if (data?.status) {
                toast.success(data?.message);
                navigate("/profile");
                queryClient.invalidateQueries(["userpodcasts"]);
            } else {
                toast.error(data?.errors[0]);
            }
            setIsLoading(false); // Stop loading when success
        },
        onError: (error) => {
            toast.error(`Error updating podcast: ${error.message}`);
            setIsLoading(false); // Stop loading when error
        },
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleFileChange = (acceptedFiles) => {
        const file = acceptedFiles[0];
        const fileType = file?.type;

        if (acceptedFiles.length === 0) {
            toast.error('No file selected. Please choose an image file.');
            return;
        }

        if (fileType && (fileType === 'image/png' || fileType === 'image/jpeg')) {
            setFormData({
                ...formData,
                image: file, // Store the new file in formData
            });

            setFiles2(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file) // Create a preview URL for display
            })));
        } else {
            toast.error('Please upload a valid image file (PNG or JPG).');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);

        // Create a FormData object to send in the request
        const updatedData = new FormData();
        updatedData.append('title', formData.title);
        updatedData.append('description', formData.description);
        if (formData.image) {
            updatedData.append('image', formData.image); // Append the new image file
        }
        updatedData.append('explicit', formData.explicit);
        updatedData.append('publisher', formData.publisher);
        updatedData.append('language', formData.language);
        updatedData.append('release_date', formData.releaseDate);
        updatedData.append('tags_id', formData.tagsId);
        updatedData.append('category_id', formData.categoryId);
        updatedData.append('podcast_id', id);

        updateMutation.mutate({ updatedData });
    };
    console.log(formData);

    return (
        <>
            <div className="container-fluid py-2  pb-5 bg-white">
                <h4 className="mb-4">Update Podcast</h4>
                {podcastLoading ? (
                    <div className='d-flex justify-content-center'><Spinner /></div>
                ) : podcastError ? (
                    <div>Error:</div>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3 col-md-12">
                            <label className="form-label">Title</label>
                            <input
                                type="text"
                                className="form-control"
                                name="title"
                                value={formData.title}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className='d-md-flex'>
                            <div className="mb-3 col-md-6 pe-md-3">
                                <label className="form-label">Description</label>
                                <textarea
                                    className="form-control"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className="mb-3 col-md-6 ps-md-3">
                                <label className="form-label">Image</label>
                                <Dropzone onDrop={handleFileChange} maxFiles={1} accept="image/jpeg, image/png">
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps()} className="dropzone upload-zone dz-clickable text-center">
                                            <input {...getInputProps()} />
                                            {files2.length > 0 ? (
                                                // New image preview
                                                files2.map((file, index) => (
                                                    <img
                                                        key={index}
                                                        src={file.preview}
                                                        alt="Preview"
                                                        style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                                                    />
                                                ))
                                            ) : formData.image ? (
                                                // Existing image from the API if no new image is selected
                                                <img
                                                    src={formData.image_link}  // Use the existing image URL from the API
                                                    alt="Current Podcast Image"
                                                    style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                                                />
                                            ) : (
                                                <p className="dz-message">Drag and drop or click to select an image</p>
                                            )}
                                        </div>
                                    )}
                                </Dropzone>
                            </div>
                        </div>


                        <div className='d-md-flex'>
                            <div className="mb-3 col-md-6 pe-md-3">
                                <label className="form-label">Podcaster Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="publisher"
                                    value={formData.publisher}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3 col-md-6 ps-md-3">
                                <label className="form-label">Language</label>
                                <RSelect
                                    options={languagesData?.data?.languages.map((lang) => ({
                                        value: lang.id,
                                        label: lang.label
                                    }))}
                                    name="language"
                                    value={languagesData?.data?.languages.find(lang => lang.id == formData.language)} // Find the language by id
                                    onChange={(selectedOption) => setFormData({
                                        ...formData,
                                        language: selectedOption.value // Get the id from the selected option
                                    })}
                                    required
                                />
                            </div>
                        </div>

                        <div className='d-md-flex'>
                            <div className="mb-3 col-md-6 pe-md-3">
                                <label className="form-label">Release Date</label>
                                <DatePicker
                                    selected={formData.releaseDate ? new Date(formData.releaseDate) : null}
                                    onChange={(date) => setFormData({ ...formData, releaseDate: date.toISOString().split('T')[0] })}
                                    dateFormat="yyyy-MM-dd"
                                    className="form-control"
                                    minDate={new Date()}
                                    required
                                />
                            </div>
                            <div className="mb-3 col-md-6 ps-md-3 ">
                                <label className="form-label">Explicit Content (strong language)</label>
                                <div className='d-md-flex'>
                                    <div className="form-check pe-2">
                                        <input
                                            type="radio"
                                            className="form-check-input"
                                            name="explicit"
                                            value="1"
                                            checked={formData.explicit === "1"}
                                            onChange={handleChange}
                                            required
                                            id='btnYes'
                                        />
                                        <label className="form-check-label" htmlFor='btnYes'>Yes</label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="radio"
                                            className="form-check-input"
                                            name="explicit"
                                            value="0"
                                            checked={formData.explicit === "0"}
                                            onChange={handleChange}
                                            required
                                            id='btnno'
                                        />
                                        <label className="form-check-label" htmlFor="btnno">No</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='d-md-flex'>
                            <div className="form-group mb-3 col-md-6 pe-md-3">
                                <label className="form-label" htmlFor="categoryId">Category</label>

                                <RSelect
                                    options={categoriesTagsData?.data?.categories.map((category) => ({
                                        value: category.id, // the id as value
                                        label: category.name // the name as label
                                    }))}
                                    name="categoryId"
                                    value={categoriesTagsData?.data?.categories
                                        .map((category) => ({
                                            value: category.id,
                                            label: category.name
                                        }))
                                        .find((cat) => cat.value == formData.categoryId)} // Match the entire object
                                    onChange={(selectedOption) => {
                                        setFormData({
                                            ...formData,
                                            categoryId: selectedOption.value
                                            // Store only the id
                                        })
                                        setCategoryId(selectedOption.value)
                                    }}
                                    required
                                />
                            </div>

                            <div className="form-group mb-3 col-md-6 ps-md-3">
                                <label className="form-label" htmlFor="tagsId">Tags</label>
                                <RSelect
                                    isMulti
                                    options={categoriesTagsData?.data?.tags.map((tag) => ({
                                        value: tag.id,
                                        label: tag.name,
                                    }))}
                                    name="tagsId"
                                    value={formData.tagsId.split(',').map((tagId) => ({
                                        value: tagId,
                                        label: categoriesTagsData?.data?.tags.find(tag => tag.id === parseInt(tagId))?.name,
                                    }))}
                                    onChange={(selectedOptions) => setFormData({
                                        ...formData,
                                        tagsId: selectedOptions.map((option) => option.value).join(','),
                                    })}
                                    required
                                />
                            </div>
                        </div>
                        <Button type="submit" color="primary" disabled={isLoading}>
                            {isLoading ? 'Updating...' : 'Update Podcast'}
                        </Button>
                    </form>
                )}
            </div>
        </>
    );
};

export default UpdatePodcastnew;
