import React from "react";

const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
        className={`prev-arrow ${className}`}
            style={{
                ...style,
                display: "block",
                borderRadius: "50%",
                padding: "12px",
                zIndex: 1,
                left: "-10px", 
            }}
            onClick={onClick}
        />
    );
};

export default SamplePrevArrow;
