import React from 'react'
import { ShimmerTitle } from "react-shimmer-effects";

export default function Shimmerfields() {
    return (

        <div className=''>
            <ShimmerTitle line={1} gap={5} variant="primary" />
        </div>
    )
}
