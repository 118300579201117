import React from 'react'
import profileimage from "../../images/Podcast/Landingpage/artist-1.jpg"
import { useNavigate } from 'react-router';
import { deletepodcastuser, fetchPodcasts, getUserPodcast } from '../../http/Post';

import "../../pages/components/css/app.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import { useMutation, useQuery } from '@tanstack/react-query';
import ShimmerLandingpage from '../../Shimmer/ShimmerLandingpage.jsx';
import SampleNextArrow from '../SampleNextArrow.jsx';
import SamplePrevArrow from '../SamplePrevArrow.jsx';
import { toast } from 'react-toastify';
import { queryClient } from '../../App.js';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { Icon } from '../../components/Component.js';
import Swal from 'sweetalert2';

export default function Podcastprofile() {
    const navigate = useNavigate();

    const gotodetailpage = (id) => {
        navigate(`/profile/episodes/${id}`);
    };

    const gotoaddpodcast = () => {
        navigate(`/add-podcast`);
    };

    const gotoupdatepodcast = (id) => {
        navigate(`/update-podcast/${id}`);
    };

    const { mutate: deletepodcastmutate, reset: deletepodcastreset } = useMutation({
        mutationKey: ["delete-podcast"],
        mutationFn: deletepodcastuser,
        onSuccess: (data) => {
            console.log(data)
            if (data?.status) {
                toast.success(data.message);
                queryClient.invalidateQueries(["userpodcasts"]);
            } else {
                toast.error(data?.message);
            }
            deletepodcastreset();
        },
        onError: (error) => {
            toast.error(`${error.message}`);
        },
    });

    const deletepodcast = async (id) => {
        console.log(`deleting ${id}`);
        Swal.fire({
            icon: "warning",
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel!",
            focusConfirm: false,
        }).then(async (result) => {
            if (result.isConfirmed) {
                // Proceed with deletion if confirmed
                await deletepodcastmutate(id);
                Swal.fire(
                    "Deleted!",
                    "Your podcast has been deleted.",
                    "success"
                );
            }

        });
    };

    const settings = {
        dots: false,
        arrows: true,
        autoplay: false,
        cssEase: "linear",
        infinite: false,
        center: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
        ],
    };

    const { data: prodcastData, error: prodcastIsError, isLoading: prodcastIsLoading } = useQuery({
        queryKey: ['userpodcasts'],
        queryFn: getUserPodcast,
        gcTime: 0,
        staleTime: Infinity,
    });
    console.log(" xyz ", prodcastData);

    if (prodcastIsLoading) return <ShimmerLandingpage />
    if (prodcastIsError) return <div>Error: </div>;


    return (
        <>
            <div className='section'>
                <div className='container-fluid '>
                    <div className='row py-2  '>
                        {/* <h4 className='text-center'>
                    Profile
                </h4> */}
                        {/* <div className='col-md-3 text-center  bg-white'>
                    <div className=''>
                        <img src={profileimage} alt="profile-image " className='rounded-5' />
                        <p className='m-0'>Zayn Malik</p>
                        <p className=''>xyzxyz@gmail.com</p>
                    </div>

                </div>
                <div className='col-md-6 '>
                    <h5>
                        Overview
                    </h5>
                    <p>
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolor optio, ab debitis accusamus nemo voluptatem, ipsa sequi eligendi distinctio libero in voluptas, reprehenderit fuga voluptatibus reiciendis obcaecati velit? Reiciendis, quisquam.
                    </p>
                </div> */}
                    </div>

                    <div className='row '>
                        <div className='d-flex justify-content-between py-2'>
                            <h6>Your Podcast</h6>
                            <div className=" px-2" onClick={gotoaddpodcast}>
                                <button className="btn btn-sm btn-danger d-none d-sm-block">+ Add Podcast</button>
                                <button className="btn btn-sm btn-danger d-md-none">+</button>
                            </div>
                        </div>
                        <div>

                            {/* <Slider {...settings} className='text-start'> */}
                            <div className='row row-cols-2 row-cols-lg-5 row-cols-md-4 ' >
                                {prodcastData?.data?.user_podcasts?.length > 0 ? (
                                    prodcastData?.data?.user_podcasts?.map((podvalue, index) => (
                                        <div className="px-2 py-2 col" >
                                            <div
                                                className="card rounded-3 h-100 border-0 pointer hover-card" key={podvalue.id}
                                            >
                                                <div className=''>
                                                    <div className='position-absolute right-0'>
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle className="dropdown-toggle btn btn-icon btn-light bg-transparent border-0 hover-black">
                                                                <Icon name="more-v"></Icon>
                                                                <DropdownMenu className='p-0'>
                                                                    <ul className="link-list-opt no-bdr">
                                                                        <li>
                                                                            <DropdownItem onClick={() => gotoupdatepodcast(podvalue.id)}>
                                                                                <Icon name="edit"></Icon>
                                                                                <span>Edit</span>
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li>
                                                                            <DropdownItem onClick={() => deletepodcast(podvalue.id)}>
                                                                                <Icon name="trash"></Icon>
                                                                                <span>Delete</span>
                                                                            </DropdownItem>
                                                                        </li>

                                                                    </ul>
                                                                </DropdownMenu>
                                                            </DropdownToggle>

                                                        </UncontrolledDropdown>
                                                    </div>
                                                    <img
                                                        src={podvalue.image_url}
                                                        alt=""
                                                        onClick={() => gotodetailpage(podvalue.id)}
                                                        className="img-responsive rounded-3  "
                                                    />
                                                </div>
                                                <div className="card-body p-0 px-1 py-1 ">

                                                    <h6 className=" fs-7  lato-font fw-bold">{podvalue.title}</h6>


                                                    <h6 className=" fs-7 truncated-text lato-font">{podvalue.description}</h6>

                                                </div>
                                            </div>

                                        </div>





                                    ))
                                ) : (
                                    <>

                                        <div className='py-3'>
                                            No Podcast Found
                                        </div>

                                    </>
                                )}
                            </div>
                        </div>


                        {/* </Slider> */}
                    </div >
                </div >
            </div >

        </>

    )
}
