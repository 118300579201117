export const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${("0" + remainingSeconds).slice(-2)} min`;
};

// dateUtils.js
export const formatDate = (dateString) => {
    const date = new Date(dateString.replace(/\.|\-/g, '/')); // Replace '.' and '-' with '/' for compatibility

    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' }); // e.g., 'Aug'
    const year = date.getFullYear();

    return `${day} ${month}, ${year}`;
};
