import React from 'react'
import { ShimmerContentBlock } from "react-shimmer-effects";
import { ShimmerCategoryItem } from "react-shimmer-effects";

export default function Shimmerpodcastdetails() {
    return (
        <>
            <div className='container-fluid py-3'>
                <ShimmerContentBlock
                    title
                    text
                    cta
                    thumbnailWidth={370}
                    thumbnailHeight={370}
                />
                <div className='py-3 bg-light px-3' >
                    <ShimmerCategoryItem
                        hasImage
                        imageType="thumbnail"
                        imageWidth={80}
                        imageHeight={80}
                        text

                    />
                    <ShimmerCategoryItem
                        hasImage
                        imageType="thumbnail"
                        imageWidth={80}
                        imageHeight={80}
                        text

                    />
                </div>
            </div>

        </>
    )
}
