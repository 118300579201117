import React from "react";

const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
        className={`next-arrow ${className}`}
            style={{
                ...style,
                display: "block",
                borderRadius: "50%",
                padding: "12px",
                zIndex: 1,
                right: "-10px",
            }}
            onClick={onClick}
        />
    );
};

export default SampleNextArrow;
