import React from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { Block } from "../components/Component";
import PodcastLandingpage from "../Podcast-pages/PodcastLandingpage";
import AudioPlayerMusic from "../Podcast-pages/AudioPlayerMusic";

export default function PodcastHome() {
  return (
    <React.Fragment>
      <Head title="SiloPodcast" />
      <PodcastLandingpage />
      {/* <AudioPlayerMusic/> */}
    </React.Fragment>
  );
}
