import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { toast } from 'react-toastify';
import { useQuery, useMutation } from '@tanstack/react-query';
import { getepisodebyid, updateepisodebyid } from '../../http/Post';
import { useNavigate, useParams } from 'react-router';
import DatePicker from "react-datepicker";
import Dropzone from 'react-dropzone';
import { Button, Spinner } from 'reactstrap';
import { queryClient } from '../../App';

const Updateepisode = () => {
    const { episodeid } = useParams();  // Get episode ID from URL params
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        image_url: null,
        audio_url: '',
        duration: '',
        published_at: '',
        explicit: '',
        transcriptions: '',
        guest_speakers: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [files2, setFiles2] = useState([]);
    const [imageFile, setImageFile] = useState(null);
    const [audioFile, setAudioFile] = useState([]);


    const { data: episodedata, error: episodeerror, isLoading: episodeloading, isSuccess } = useQuery({
        queryKey: ['episodeid', episodeid],
        queryFn: () => getepisodebyid({ id: episodeid }),
    });
    console.log(episodeid);

    useEffect(() => {
        if (isSuccess) {
            setFormData({
                title: episodedata?.data?.episode?.title || '',
                description: episodedata?.data?.episode?.description || '',
                image_url: episodedata?.data?.episode?.image_url || '',
                audio_url: episodedata?.data?.episode?.audio_url || '',
                duration: episodedata?.data?.episode?.duration || '',
                published_at: episodedata?.data?.episode?.published_at || '',
                transcriptions: episodedata?.data?.episode?.transcriptions || '',
                explicit: episodedata?.data?.episode?.explicit || '',
                guest_speakers: episodedata?.data?.episode?.guest_speakers || '',
                podcast_id: episodedata?.data?.episode?.podcast_id || '',
                audio_file_name: episodedata?.data?.episode?.audio_file_name || '',

            });
            console.log(episodedata?.data?.episode?.podcast_id, "aisjcndsn");
        }
    }, [isSuccess, episodedata]);

    const navigate = useNavigate();

    // Mutation for updating episode
    const updateMutation = useMutation({
        mutationFn: ({ updatedData }) => updateepisodebyid({ updatedData }),
        onSuccess: (data) => {
            if (data?.status) {
                toast.success(data?.message);
                navigate(`/profile/episodes/${formData.podcast_id}`)
                queryClient.invalidateQueries(["userpodcasts"]);
            } else {
                toast.error(data?.errors[0]);
                console.log(data?.errors[0])
            }
            setIsLoading(false); // Stop loading when success
        },
        onError: (error) => {
            toast.error(`Error updating episode: ${error.message}`);
            console.log(error.message);
            setIsLoading(false); // Stop loading when error
        },
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleFileChange = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            const fileType = file.type;

            if (fileType === 'image/png' || fileType === 'image/jpeg') {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    image_url: '',  // Clear existing image_url when new image is selected
                }));

                // Set preview for the new image using URL.createObjectURL
                setFiles2([Object.assign(file, { preview: URL.createObjectURL(file) })]);
            } else {
                toast.error('Please upload a valid image file (PNG or JPG)');
            }
        }
    };
    const handleAudioChange = (file) => {
        setAudioFile([file]); // Update the audio file state
        setFormData((prevData) => ({
            ...prevData,
            audio_url: URL.createObjectURL(file), // Create an object URL for the selected file
        }));
    };


    const handleSubmit = (e) => {

        e.preventDefault();
        setIsLoading(true);

        // Create a FormData object to send in the request
        const updatedData = new FormData();
        updatedData.append('title', formData.title);
        updatedData.append('description', formData.description);
        if (files2.length > 0) {
            updatedData.append('image_url', files2[0]);  // This will send the new image
        }

        if (audioFile.length > 0) {
            updatedData.append('audio_url', audioFile[0]);  // This will send the new audio file
        }

        updatedData.append('duration', formData.duration);
        updatedData.append('published_at', formData.published_at);
        updatedData.append('transcriptions', formData.transcriptions);
        updatedData.append('explicit', formData.explicit);
        updatedData.append('guest_speakers', formData.guest_speakers);
        updatedData.append('podcast_id', formData.podcast_id);
        updatedData.append('episode_id', episodeid);

        updateMutation.mutate({ updatedData });
    };
    console.log(formData);
    return (
        <>
            <div className="container-fluid py-2 pb-5 bg-white px-3">
                <h4 className="mb-4">Update Episode</h4>
                {episodeloading ? (
                    <div className='d-flex justify-content-center'><Spinner /></div>
                ) : episodeerror ? (
                    <div>Error:{episodeerror.message}</div>
                ) : (
                    <div className='row'>

                        <form onSubmit={(e) => handleSubmit(e)}>
                            <div className="mb-3 col-md-12">
                                <label className="form-label">Title</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="title"
                                    value={formData.title}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className='d-md-flex'>
                                <div className="mb-3 col-md-6 pe-md-3 pe-md-3">
                                    <label className="form-label">Description</label>
                                    <textarea
                                        className="form-control"
                                        name="description"
                                        value={formData.description}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3 col-md-6 ps-md-3">
                                    <label className="form-label">Transcriptions</label>
                                    <textarea
                                        className="form-control"
                                        name="transcriptions"
                                        value={formData.transcriptions}
                                        onChange={handleChange}
                                    />
                                </div>

                            </div>

                            <div className='d-md-flex'>
                                <div className="mb-3 col-md-6 pe-md-3">
                                    <label className="form-label">Image</label>
                                    <Dropzone onDrop={handleFileChange} maxFiles={1} accept="image/jpeg, image/png">
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()} className="dropzone upload-zone dz-clickable text-center">
                                                <input {...getInputProps()} />
                                                {files2.length > 0 ? (
                                                    files2.map((file, index) => (
                                                        <img
                                                            key={index}
                                                            src={file.preview}
                                                            alt="Preview"
                                                            style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                                                        />
                                                    ))
                                                ) : formData.image_url ? (
                                                    <img
                                                        src={formData.image_url}
                                                        alt="Current Episode Image"
                                                        style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                                                    />
                                                ) : (
                                                    <p className="dz-message">Drag and drop or click to select an image</p>
                                                )}
                                            </div>
                                        )}
                                    </Dropzone>
                                </div>
                                <div className="mb-3 col-md-6 ps-md-3">
                                    <label className="form-label">Audio</label>
                                    <Dropzone
                                        onDrop={(acceptedFiles) => {
                                            // Handle the new audio file change
                                            if (acceptedFiles.length > 0) {
                                                const newFile = acceptedFiles[0];
                                                handleAudioChange(newFile); // Update the file state in your form handler
                                            }
                                        }}
                                        maxFiles={1}
                                        accept="audio/mpeg, audio/wav"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()} className="dropzone upload-zone dz-clickable text-center">
                                                <input {...getInputProps()} />
                                                {audioFile.length > 0 ? (

                                                    <p> {audioFile[0].name}</p>
                                                ) : formData.audio_file_name ? (

                                                    <p>{formData.audio_file_name.split('/').pop()}</p>
                                                ) : (
                                                    <p className="dz-message">Drag and drop or click to select an audio file</p>
                                                )}
                                            </div>
                                        )}
                                    </Dropzone>
                                </div>
                            </div>

                            <div className='d-md-flex'>
                                <div className="mb-3 col-md-6 pe-md-3">
                                    <label className="form-label">Guest Speakers</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="guest_speakers"
                                        value={formData.guest_speakers}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-3 col-md-6 ps-md-3">
                                    <label className="form-label">Release Date</label>
                                    <DatePicker
                                        selected={formData.published_at ? new Date(formData.published_at) : null}
                                        onChange={(date) => setFormData({ ...formData, published_at: date.toISOString().split('T')[0] })}
                                        dateFormat="yyyy-MM-dd"
                                        className="form-control"
                                        minDate={new Date()}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="mb-3 col-md-6">
                                <label className="form-label">Explicit Content (strong language)</label>
                                <div className='d-flex'>
                                    <div className="form-check pe-2">
                                        <input
                                            type="radio"
                                            className="form-check-input"
                                            name="explicit"
                                            value="1"
                                            checked={formData.explicit === "1"}
                                            onChange={handleChange}
                                            required
                                            id='btnYes'
                                        />
                                        <label className="form-check-label" htmlFor='btnYes'>Yes</label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="radio"
                                            className="form-check-input"
                                            name="explicit"
                                            value="0"
                                            checked={formData.explicit === "0"}
                                            onChange={handleChange}
                                            required
                                            id='btnno'
                                        />
                                        <label className="form-check-label" htmlFor="btnno">No</label>
                                    </div>
                                </div>
                            </div>






                            <div className="mb-3 pt-2" >
                                <Button color="primary" type="submit" disabled={isLoading}>
                                    {isLoading ? 'Updating...' : 'Update Episode'}
                                </Button>
                            </div>
                        </form>
                    </div>
                )}
            </div >
        </>
    );
};

export default Updateepisode;
