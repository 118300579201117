import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getCookie } from '../../utils/Utils';
import { toast } from 'react-toastify';
import { useQuery, useMutation } from '@tanstack/react-query';
import { addPodcastApi, fetchCategoriesAndTags, languageOfpodcast } from '../../http/Post';
import Dropzone from 'react-dropzone';
import { Button, Spinner } from 'reactstrap';
import { RSelect } from '../../components/Component';
import { Navigate, useNavigate } from 'react-router';
import DatePicker from "react-datepicker";
import Shimmerfields from '../../Shimmer/Shimmerfields';


const AddPodcast = () => {
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        image: null,
        explicit: '0',
        podcasterName: '',
        language: 'English',
        releaseDate: '',
        tagsId: [],
        categoryId: '',
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const resetForm = () => {
        setFormData({
            title: '',
            description: '',
            image: null,
            explicit: '',
            podcasterName: '',
            language: '',
            releaseDate: '',
            tagsId: [],
            categoryId: ''
        });
    };

    const [files2, setFiles2] = useState([]);
    const [categoryId, setCategoryId] = useState([]);

    const { data, error, isError, isLoading: isFetching } = useQuery({
        queryKey: ['categories-and-tags', categoryId],
        queryFn: () => fetchCategoriesAndTags(categoryId),
    });

    console.log(`Categories :: ${data}`)

    // const { languagedata, languageerror, languageisError, isLoading: languageisFetching } = useQuery({
    //     queryKey: ['language'],
    //     queryFn: languageOfpodcast,
    // });

    const {
        data: languagedata,
        isLoading: languageerror,
        isRefetching: languageisFetching,
        refetch: languageFetching,
    } = useQuery({
        queryKey: ["language"],
        queryFn: async () => languageOfpodcast(),
        gcTime: 0,
        staleTime: Infinity,
    });
    // console.log(languagedata)

    const navigate = useNavigate()

    // UseMutation for adding podcast
    const mutation = useMutation({
        mutationFn: addPodcastApi,
        onSuccess: (data) => {
            if (data.status) {
                toast.success(data.message);
                navigate('/profile')
                resetForm();
                setFiles2([]);
                console.log("first data")

            }
            else {
                toast.error(data.errors[0]);
            }
        },
        onError: (error) => {
            toast.error(error.message);
        },
        onSettled: () => {
            setIsLoading(false);
        },
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };
    const validateForm = () => {
        let newErrors = {};

        // Title validation: min 5, max 100 characters, text only
        if (!formData.title || formData.title.length < 5 || formData.title.length > 100 || !/^[A-Za-z ]+$/.test(formData.title)) {
            newErrors.title = 'Title must be 5-100 characters long and contain only letters';
        }

        // Description validation: min 5, max 250 characters
        if (!formData.description || formData.description.length < 5 || formData.description.length > 250) {
            newErrors.description = 'Description must be 5-250 characters long';
        }

        // Podcaster name validation: min 5, max 100 characters, alphanumerical
        if (!formData.podcasterName || formData.podcasterName.length < 5 || formData.podcasterName.length > 100 || !/^[A-Za-z0-9 ]+$/.test(formData.podcasterName)) {
            newErrors.podcasterName = 'Podcaster name must be 5-100 characters long and contain only alphanumerics';
        }
        // if (!formData.image) {
        //     // newErrors.image = 'image is required';
        //     toast.error('Image required')
        // }
        // Release date validation (should not be empty)
        if (!formData.releaseDate) {
            newErrors.releaseDate = 'Release date is required';
        }

        // Category validation
        if (!formData.categoryId) {
            newErrors.categoryId = 'Category is required';
        }

        // Tags validation
        if (!formData.tagsId.length) {
            newErrors.tagsId = 'Tags are required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Returns true if no errors
    };

    const handleFileChange = (acceptedFiles) => {
        const file = acceptedFiles[0];
        const fileType = file?.type;
        if (acceptedFiles.length === 0) {
            toast.error('No file selected. Please choose an image file.');
            return;
        }

        if (fileType && (fileType === 'image/png' || fileType === 'image/jpeg')) {
            setFormData({
                ...formData,
                image: file,
            });
            setFiles2(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file) // Create a preview URL
            })));
        } else {
            toast.error('Please upload a valid image file (PNG or JPG).');
        }
    };
    const removeFile = (index) => {
        const newFiles = files2.filter((_, i) => i !== index); // Remove the file at the specified index
        setFiles2(newFiles);
        if (newFiles.length === 0) {
            setFormData({ ...formData, image: null }); // Reset the image field if no files are left
        } else {
            setFormData({ ...formData, image: newFiles[0] }); // Update formData to the new first file
        }
    };
    const defaultOptions = data?.data?.tags.map(tag => ({
        value: tag.id,
        label: tag.name,
    }));
    const handleTagsChange = (selectedOptions) => {
        // Extract selected tag ids
        const selectedTags = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setFormData({
            ...formData,
            tagsId: selectedTags, // Update the tagsId array in formData
        });
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        if (!validateForm()) {
            toast.error('Please fix the errors in the form');
            return;
        }
        setIsLoading(true);

        const data = new FormData();
        data.append('title', formData.title);
        data.append('description', formData.description);
        data.append('image_url', formData.image);
        data.append('explicit', formData.explicit);
        data.append('publisher', formData.podcasterName);
        data.append('language', formData.language.value);
        data.append('release_date', formData.releaseDate);
        data.append('tags_id', formData.tagsId);
        data.append('category_id', formData.categoryId.value);

        mutation.mutate(data);
    };

    console.log("formData", formData)

    return (
        <div className="container-fluid py-2 px-3 pb-5 bg-white">
            <h4 className="mb-4">Add New Podcast</h4>
            <div className='row'>

                <form onSubmit={handleSubmit}>
                    {/* Form fields */}
                    <div className="mb-3 col-md-12">
                        <label className="form-label">Title</label>
                        <input
                            type="text"
                            className="form-control"
                            name="title"
                            value={formData.title}
                            onChange={handleChange}
                            required
                        />
                        {errors.title && <div className="text-danger">{errors.title}</div>}
                    </div>

                    <div className='d-md-flex '>
                        <div className="mb-3 col-md-6 pe-md-3">
                            <label className="form-label">Description</label>
                            <textarea
                                className="form-control"
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                                required
                            />
                            {errors.description && <div className="text-danger">{errors.description}</div>}
                        </div>
                        <div className='col-md-6 ps-md-3'>
                            <label className="form-label">Image</label>
                            <Dropzone onDrop={handleFileChange} maxFiles={1}>
                                {({ getRootProps, getInputProps }) => (
                                    <section>
                                        <div {...getRootProps()} className="dropzone upload-zone dz-clickable text-center">
                                            <input {...getInputProps()} />
                                            {files2.length === 0 ? (
                                                <div className="dz-message">
                                                    <span className="dz-message-text">Drag and drop file</span>
                                                    <span className="dz-message-or">or</span>
                                                    <Button color="primary">SELECT</Button>
                                                </div>
                                            ) : (
                                                files2.map((file, index) => (
                                                    <div
                                                        key={file.name}
                                                        className="dz-preview dz-processing dz-image-preview dz-complete"
                                                    >
                                                        <div className="dz-image">
                                                            <img src={file.preview} alt="preview" />
                                                        </div>
                                                        {/* <Button
                                                    onClick={() => removeFile(index)}
                                                    className="close-button"
                                                    color="danger"
                                                >
                                                    &times; 
                                                </Button> */}
                                                    </div>
                                                ))
                                            )}
                                        </div>
                                        {/* {errors.image && <div className="text-danger">{errors.image}</div>} */}
                                    </section>
                                )}
                            </Dropzone>
                        </div>

                    </div>


                    <div className='d-md-flex pt-2'>
                        <div className="mb-3  col-md-6 pe-md-3">
                            <label className="form-label">Podcaster Name</label>
                            <input
                                type="text"
                                className="form-control"
                                name="podcasterName"
                                value={formData.podcasterName}
                                onChange={handleChange}
                                required
                            />
                            {errors.podcasterName && <div className="text-danger">{errors.podcasterName}</div>}
                        </div>

                        <div className="mb-3 col-md-6 ps-md-3">
                            <label className="form-label">Language</label>
                            <RSelect
                                options={languagedata?.data?.languages.map((lang) => ({
                                    value: lang.id,
                                    label: lang.label
                                }))}

                                name="language"
                                value={formData.language}
                                onChange={(selectedOption) => setFormData({
                                    ...formData,
                                    language: selectedOption
                                })}
                                required
                            />

                        </div>
                    </div>


                    <div className='d-md-flex pt-2'>
                        <div className="mb-3 col-md-6 pe-md-3">
                            <label className="form-label">Release Date</label>
                            <DatePicker
                                selected={formData.releaseDate ? new Date(formData.releaseDate) : null}
                                onChange={(date) => setFormData({ ...formData, releaseDate: date.toISOString().split('T')[0] })}
                                dateFormat="yyyy-MM-dd"
                                className="form-control"
                                minDate={new Date()} // Prevent selecting past dates
                                required
                            />
                        </div>
                        <div className="mb-3 ps-md-3">
                            <label className="form-label">Explicit Content (strong language)</label>
                            <div className='d-md-flex'>
                                <div className="form-check pe-2">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        name="explicit"
                                        value="1"
                                        checked={formData.explicit === "1"}
                                        onChange={handleChange}
                                        required
                                        id='btnYes'
                                    />
                                    <label className="form-check-label" htmlFor='btnYes'>Yes</label>
                                </div>
                                <div className="form-check">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        name="explicit"
                                        value="0"
                                        defaultChecked
                                        checked={formData.explicit === "0"}
                                        onChange={handleChange}
                                        required
                                        id='btnno'
                                    />
                                    <label className="form-check-label" htmlFor="btnno">No</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='d-md-flex pt-2'>
                        <div className="form-group mb-3 col-md-6 pe-md-3">
                            <label className="form-label" htmlFor="categoryId">Category</label>
                            {isFetching ? (
                                <div><Shimmerfields /></div>
                            ) : isError ? (
                                <div>Error: {error.message}</div>
                            ) : (
                                <RSelect
                                    options={data?.data?.categories.map((category) => ({
                                        value: category.id, // the id as value
                                        label: category.name // the name as label
                                    }))}
                                    name="categoryId"
                                    value={formData.categoryId}
                                    onChange={(selectedOption) => {
                                        setFormData({
                                            ...formData,
                                            categoryId: selectedOption // only store the selected value
                                        })
                                        setCategoryId(selectedOption.value)
                                        // console.log("selectedOption:::", selectedOption)
                                    }
                                    }
                                    required
                                />
                            )}
                        </div>



                        <div className="form-group col-md-6 ps-md-3">
                            <label className="form-label">Tags</label>
                            {isFetching ? (
                                <div><Shimmerfields /> </div>
                            ) : isError ? (
                                <div>Error: {error.message}</div>
                            ) : (
                                <RSelect
                                    options={defaultOptions}
                                    name="tagsId"
                                    isMulti
                                    isDisabled={!formData.categoryId}
                                    onChange={handleTagsChange} // Handle change event
                                    placeholder="Select tags"
                                    required
                                />
                            )}
                        </div>

                    </div>




                    <div className='py-2'>
                        <button type="submit" className="btn btn-primary" disabled={isLoading || mutation.isLoading}>
                            {isLoading || mutation.isLoading ? (
                                <span><Spinner color="light" size="sm" className='p-0' /></span>

                            ) : (
                                'Add Podcast'
                            )}
                        </button>
                    </div>
                </form>

            </div>
        </div >
    );
};

export default AddPodcast;