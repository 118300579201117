import React from "react";
import { ShimmerPostList } from "react-shimmer-effects";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { ShimmerButton } from "react-shimmer-effects";
import { ShimmerTitle } from "react-shimmer-effects";
export default function ShimmerLandingpage() {
    return (
        <>
            <div className="container-fluid bg-white py-4">

                <div className=" row row-cols-7">
                    <div className="col">
                        <ShimmerTitle line={1} gap={10} variant="primary" />
                    </div>
                    <div className="col">
                        <ShimmerTitle line={1} gap={10} variant="primary" />
                    </div>
                    <div className="col">
                        <ShimmerTitle line={1} gap={10} variant="primary" />
                    </div>
                    <div className="col">
                        <ShimmerTitle line={1} gap={10} variant="primary" />
                    </div>
                    <div className="col">
                        <ShimmerTitle line={1} gap={10} variant="primary" />
                    </div>
                    <div className="col">
                        <ShimmerTitle line={1} gap={10} variant="primary" />
                    </div>
                    <div className="col">
                        <ShimmerTitle line={1} gap={10} variant="primary" />
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col-md-3">
                        <ShimmerTitle line={1} gap={10} variant="primary" />
                    </div>
                </div> */}
                <div className="podcast-shimmer-dark row row-cols-5 py-3">
                    <div className="col">
                        <ShimmerThumbnail height={200} rounded />
                        <ShimmerTitle line={1} gap={0} variant="primary" />
                    </div>
                    <div className="col">
                        <ShimmerThumbnail height={200} rounded />
                        <ShimmerTitle line={1} gap={0} variant="primary" />
                    </div>
                    <div className="col">
                        <ShimmerThumbnail height={200} rounded />
                        <ShimmerTitle line={1} gap={0} variant="primary" />
                    </div>
                    <div className="col">
                        <ShimmerThumbnail height={200} rounded />
                        <ShimmerTitle line={1} gap={0} variant="primary" />
                    </div>
                    <div className="col">
                        <ShimmerThumbnail height={200} rounded />
                        <ShimmerTitle line={1} gap={0} variant="primary" />
                    </div>

                    {/* <ShimmerSimpleGallery card imageHeight={100} row={3} col={4} caption           /> */}
                </div>
                <div className="podcast-shimmer-dark row row-cols-5 py-3">
                    <div className="col">
                        <ShimmerThumbnail height={200} rounded />
                        <ShimmerTitle line={1} gap={0} variant="primary" />
                    </div>
                    <div className="col">
                        <ShimmerThumbnail height={200} rounded />
                        <ShimmerTitle line={1} gap={0} variant="primary" />
                    </div>
                    <div className="col">
                        <ShimmerThumbnail height={200} rounded />
                        <ShimmerTitle line={1} gap={0} variant="primary" />
                    </div>
                    <div className="col">
                        <ShimmerThumbnail height={200} rounded />
                        <ShimmerTitle line={1} gap={0} variant="primary" />
                    </div>
                    <div className="col">
                        <ShimmerThumbnail height={200} rounded />
                        <ShimmerTitle line={1} gap={0} variant="primary" />
                    </div>

                    {/* <ShimmerSimpleGallery card imageHeight={100} row={3} col={4} caption           /> */}
                </div>
            </div>

        </>
    )

}
