
import Cookies from "js-cookie";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL || `https://api.silocloud.io/`;
const headers = {
    authToken: Cookies.get("authToken", undefined),
    "Content-Type": "application/json",
};

// Example of a POST request
// export const postRequest = async (route) => {
//     try {
//         let config = {
//             method: "post",
//             url: `${BASE_URL}${route}`,
//             headers: headers
//             //   data: JSON.stringify(data),
//             // data: data,
//         };

//         const response = await axios(config);
//         return response.data;
//     } catch (error) {
//         console.error("Error post data:", error);
//     }
// };

// Example of a GET request
export const getRequest = async (route) => {
    try {
        let config = {
            method: "GET",
            url: `${BASE_URL}${route}`,
            headers: headers
        };

        const response = await axios(config);
        return response.data;
    } catch (error) {
        console.error("Error GET data:", error);
    }
};

export const postRequestData = async (data, route) => {
    try {
        const response = await fetch(`${BASE_URL}${route}`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
        });

        const Response = await response.json();

        if (Response.type !== "success") {
            throw new Error("Failed to fetch data From api-services");
        }

        return Response;

    } catch (error) {
        return error.message;
    }
};

export async function getAllNotificationHandler({ limit = 10, offset = 0, module = 6, recent = 10, view_all = 1 }) {
    const response = await fetch(`${BASE_URL}api/v1/public/get-notifications`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ limit, offset, module, recent }),
    });

    if (!response.ok) {
        const errorData = await response.json();

        const error = new Error("An Error occurred while getting all notifications.");
        error.info = errorData;
        error.code = `${response.status}`;
        throw error;
    }

    return await response.json();
}

export async function setNotificationCountHandler({ notification_id = 0, status = 1, module = 6 }) {
    const response = await fetch(`${BASE_URL}api/v1/public/set-notification-status`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ module, status, notification_id }),
    });

    if (!response.ok) {
        const errorData = await response.json();
        const error = new Error("An Error occurred while setting notification count.");
        error.info = errorData;
        error.code = `${response.status}`;
        throw error;
    }

    return await response.json();
}

export const fetchCloudSearchApi = async (route) => {
    let response = await fetch(`${process.env.REACT_APP_API_URL}${route}`, {
        method: "GET",
        cache: "no-store",
        headers: headers,
    });
    if (!response.ok) {
        const errorData = await response.json();
        const error = new Error("An Error occurred while AppDropdown.");
        error.info = errorData;
        error.code = `${response.status}`;
        throw error;
    }
    return await response.json();
};


