import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { addEpisode } from "../../http/Post";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone";
import { Button, Spinner } from "reactstrap";
import ReactDatePicker from "react-datepicker";
import { useNavigate, useParams } from "react-router";

const AddEpisode = () => {
    // State to hold form input
    const { episodeid } = useParams();  // Get podcast ID from URL params
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        title: "",
        description: "",
        audio_url: "",
        published_at: "",
        explicit: "0",
        image_url: "",
        transcriptions: "",
        guest_speakers: "",
    });
    const [files2, setFiles2] = useState([]);
    const [imageFile, setImageFile] = useState(null);
    const [audioFile, setAudioFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // Tanstack React Query Mutation
    console.log(episodeid, "idddd")
    const mutation = useMutation({
        mutationFn: addEpisode,
        onSuccess: (data) => {
            if (data.status) {
                toast.success(data.message);
                navigate(`/profile/episodes/${episodeid}`)
                setFormData({
                    title: "",
                    description: "",
                    audio_url: "",
                    published_at: "",
                    explicit: false,
                    image_url: "",
                    transcriptions: "",
                    guest_speakers: "",
                });
                setImageFile(null);
                setAudioFile(null);
            } else {
                toast.error(data.errors[0]);
            }
        },
        onError: (error) => {
            toast.error(error.message);
        },
        onSettled: () => {
            setIsLoading(false);
        },
    });

    // Handle input changes
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const validateForm = () => {
        const errors = {};
        // if (formData.title.length < 5 || formData.title.length > 255) {
        //     errors.title = "Title must be between 5 and 255 characters.";
        // }
        // if (formData.description.length < 5 || formData.description.length > 255) {
        //     errors.description = "Description must be between 5 and 255 characters.";
        // }
        // if (formData.audio_url.length < 5 || formData.audio_url.length > 255) {
        //     errors.audio_url = "Audio URL must be between 5 and 255 characters.";
        // }
        return errors;
    };


    const handleDropImage = (acceptedFiles) => {
        const filesWithPreview = acceptedFiles.map((file) => {
            return {
                ...file,
                preview: URL.createObjectURL(file), // Create a preview URL
            };
        });
        setFiles2(filesWithPreview);

        // Update formData but only store the file itself, not the preview.
        setFormData((prev) => ({
            ...prev,
            image_url: acceptedFiles[0], // Store the actual file for submission
        }));
    };


    const handleDropAudio = (acceptedFiles) => {
        const file = acceptedFiles[0];

        // Store the actual file for submission
        setAudioFile(file);

        // Store the object URL for preview purposes only, not for form submission
        setFormData((prev) => ({
            ...prev,
            audio_url: URL.createObjectURL(file), // This is just for preview, not for submission
        }));
    };


    // Handle form submit
    const handleSubmit = (e) => {
        e.preventDefault();

        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            Object.values(errors).forEach((error) => toast.error(error));
            return;
        }
        // if (!validateForm()) {
        //     toast.error('Please fix the errors in the form');
        //     return;
        // }
        setIsLoading(true);

        const data = new FormData();

        data.append('podcast_id', episodeid);
        data.append('title', formData.title);
        data.append('description', formData.description);
        if (formData.image_url) {
            data.append('image_url', formData.image_url);
        }

        // Append the audio file (this sends the actual file, not the blob or URL)
        if (audioFile) {
            data.append('audio_url', audioFile); // The file itself is appended here
        }
        data.append('explicit', formData.explicit);
        data.append('transcriptions', formData.transcriptions);

        data.append('published_at', formData.published_at);
        data.append('guest_speakers', formData.guest_speakers);

        mutation.mutate(data);
    };
    console.log(episodeid); // Check if the file is correctly stored
    console.log(formData.audio_url); // Check if the file is correctly stored

    return (
        <div className="container-fluid py-3 bg-white px-3">
            <h4 className="">Add Episode</h4>
            <div className="row">
                <form onSubmit={handleSubmit} className="">


                    <div className="mb-3 col-md-12 ">
                        <label className="form-label">Title</label>
                        <input
                            type="text"
                            name="title"
                            className="form-control"
                            value={formData.title}
                            onChange={handleChange}
                        />
                    </div>



                    <div className="d-md-flex ">
                        <div className="mb-3 col-md-6 pe-md-3">
                            <label className="form-label">Description</label>
                            <textarea
                                name="description"
                                className="form-control"
                                value={formData.description}
                                onChange={handleChange}
                            ></textarea>
                        </div>
                        <div className="mb-3 col-md-6 ps-md-3">
                            <label className="form-label">Transcriptions</label>
                            <textarea
                                name="transcriptions"
                                className="form-control"
                                value={formData.transcriptions}
                                onChange={handleChange}
                            ></textarea>
                        </div>


                    </div>


                    <div className="d-md-flex">
                        <div className="mb-3 col-md-6 pe-md-3">
                            <label className="form-label">Release Date</label>
                            <ReactDatePicker
                                selected={formData.published_at ? new Date(formData.published_at) : null}
                                onChange={(date) => setFormData({ ...formData, published_at: date.toISOString().split('T')[0] })}
                                dateFormat="yyyy-MM-dd"
                                className="form-control"
                                minDate={new Date()} // Prevent selecting past dates
                                required
                            />
                        </div>
                        <div className="mb-3 col-md-6 ps-md-3">
                            <label className="form-label">Guest Speaker</label>
                            <input
                                type="text"
                                name="guest_speakers"
                                className="form-control"
                                value={formData.guest_speakers}
                                onChange={handleChange}
                            />
                        </div>

                    </div>
                    <div className="d-md-flex">
                        <div className="mb-3 col-md-6 pe-md-3">
                            <label className="form-label">Image</label>
                            <Dropzone onDrop={handleDropImage} maxFiles={1}>
                                {({ getRootProps, getInputProps }) => (
                                    <section>
                                        <div {...getRootProps()} className="dropzone upload-zone dz-clickable text-center">
                                            <input {...getInputProps()} />
                                            {files2.length === 0 ? (
                                                <div className="dz-message">
                                                    <span className="dz-message-text">Drag and drop file</span>
                                                    <span className="dz-message-or">or</span>
                                                    <Button color="primary">SELECT</Button>
                                                </div>
                                            ) : (
                                                files2.map((file, index) => (
                                                    <div key={index} className="dz-preview dz-processing dz-image-preview dz-complete">
                                                        <div className="dz-image">
                                                            <img src={file.preview} alt="preview" style={{ maxWidth: "100%", height: "auto" }} />
                                                        </div>
                                                    </div>
                                                ))
                                            )}
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </div>

                        <div className="mb-3 col-md-6 ps-3">
                            <label className="form-label">Audio File</label>
                            <Dropzone onDrop={handleDropAudio} accept={["audio/mpeg", "audio/wav"]}>
                                {({ getRootProps, getInputProps }) => (
                                    <section>
                                        <div {...getRootProps()} className="dropzone upload-zone dz-clickable">
                                            <input {...getInputProps()} />
                                            {audioFile ? (
                                                <>
                                                    <p>{audioFile.name}</p>

                                                </>
                                            ) : (
                                                <div className="dz-message">
                                                    <span className="dz-message-text">Drag and drop file or</span>
                                                    <Button color="primary">SELECT</Button>
                                                </div>
                                            )}
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </div>

                    </div>
                    <div className="mb-3 col-md-6">
                        <label className="form-label">Explicit Content (strong language)</label>
                        <div className='d-flex'>
                            <div className="form-check pe-2">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    name="explicit"
                                    value="1"
                                    checked={formData.explicit === "1"}
                                    onChange={handleChange}
                                    required
                                    id='btnYes'
                                />
                                <label className="form-check-label" htmlFor='btnYes'>Yes</label>
                            </div>
                            <div className="form-check">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    name="explicit"
                                    value="0"
                                    defaultChecked
                                    checked={formData.explicit === "0"}
                                    onChange={handleChange}
                                    required
                                    id='btnno'
                                />
                                <label className="form-check-label" htmlFor="btnno">No</label>
                            </div>
                        </div>
                    </div>

                    <div className='py-2'>
                        <button type="submit" className="btn btn-primary" disabled={isLoading || mutation.isLoading}>
                            {isLoading || mutation.isLoading ? (
                                <span><Spinner color="light" size="sm" className='p-0' /></span>

                            ) : (
                                'Add Episode'
                            )}
                        </button>
                    </div>
                </form>
            </div >

        </div >

    );
};

export default AddEpisode;
