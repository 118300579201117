import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { addEpisode } from "../../http/Post";
import { toast } from "react-toastify";

const AddEpisode = () => {
    // State to hold form input
    const [formData, setFormData] = useState({
        podcast_id: "",
        title: "",
        description: "",
        audio_url: "",
        published_at: "",
        explicit: false,
        image_url: "",
        transcriptions: "",
        guest_speaker: "",
    });

    // Tanstack React Query Mutation
    const mutation = useMutation({
        mutationFn: addEpisode,
        onSuccess: (data) => {
            if (data.status) {
                toast.success(data.message);
                // Reset the form if needed
                setFormData({
                    podcast_id: "",
                    title: "",
                    description: "",
                    audio_url: "",
                    published_at: "",
                    explicit: false,
                    image_url: "",
                    transcriptions: "",
                    guest_speaker: "",
                });
            } else {
                toast.error(data.errors[0]);
            }
        },
        onError: (error) => {
            toast.error(error.message);
        },
    });

    // Handle input changes
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    // Handle form submit
    const handleSubmit = (e) => {
        e.preventDefault();

        // if (!validateForm()) {
        //     toast.error('Please fix the errors in the form');
        //     return;
        // }
        // setIsLoading(true);

        // const data = new FormData();
        // data.append('title', formData.title);
        // data.append('description', formData.description);
        // if (formData.image) {
        //     data.append('image_url', formData.image);
        // }
        // data.append('explicit', formData.explicit);
        // data.append('publisher', formData.podcasterName);
        // data.append('language', formData.language.value);
        // data.append('release_date', formData.releaseDate);
        // data.append('tags_id', formData.tagsId);
        // data.append('category_id', formData.categoryId.value);

        // mutation.mutate(data);
    };
    return (
        <div className="container mt-5">
            <h2>Add Episode</h2>
            <form onSubmit={handleSubmit} className="mt-4">
                <div className="mb-3">
                    <label className="form-label">Podcast ID</label>
                    <input
                        type="text"
                        name="podcast_id"
                        className="form-control"
                        value={formData.podcast_id}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-3">
                    <label className="form-label">Title</label>
                    <input
                        type="text"
                        name="title"
                        className="form-control"
                        value={formData.title}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-3">
                    <label className="form-label">Description</label>
                    <textarea
                        name="description"
                        className="form-control"
                        value={formData.description}
                        onChange={handleChange}
                    ></textarea>
                </div>

                <div className="mb-3">
                    <label className="form-label">Audio URL</label>
                    <input
                        type="text"
                        name="audio_url"
                        className="form-control"
                        value={formData.audio_url}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-3">
                    <label className="form-label">Published At</label>
                    <input
                        type="text"
                        name="published_at"
                        className="form-control"
                        value={formData.published_at}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-3 form-check">
                    <input
                        type="checkbox"
                        name="explicit"
                        className="form-check-input"
                        checked={formData.explicit}
                        onChange={handleChange}
                    />
                    <label className="form-check-label">Explicit Content</label>
                </div>

                <div className="mb-3">
                    <label className="form-label">Image URL</label>
                    <input
                        type="text"
                        name="image_url"
                        className="form-control"
                        value={formData.image_url}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-3">
                    <label className="form-label">Transcriptions</label>
                    <textarea
                        name="transcriptions"
                        className="form-control"
                        value={formData.transcriptions}
                        onChange={handleChange}
                    ></textarea>
                </div>

                <div className="mb-3">
                    <label className="form-label">Guest Speaker</label>
                    <input
                        type="text"
                        name="guest_speaker"
                        className="form-control"
                        value={formData.guest_speaker}
                        onChange={handleChange}
                    />
                </div>

                <button type="submit" className="btn btn-primary" disabled={mutation.isLoading}>
                    {mutation.isLoading ? "Adding..." : "Add Episode"}
                </button>

                {mutation.isError && <p className="text-danger mt-2">Error adding episode</p>}
                {mutation.isSuccess && <p className="text-success mt-2">Episode added successfully!</p>}
            </form>
        </div>
    );
};

export default AddEpisode;
