import React from "react";
import { Button, Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router";
import { podcastcard } from "./Podcastdata";
import { cutWord } from "./Podcastdata";
import { Artistdetails } from "./Podcastdata";
import podcastimg from "../images/Podcast/Landingpage/podcast-1.jpg"
import "../pages/components/css/app.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import SampleNextArrow from "./SampleNextArrow";
import SamplePrevArrow from "./SamplePrevArrow";
import { useQuery } from '@tanstack/react-query';
import { fetchPodcasts } from "../http/Post";
import ShimmerLandingpage from "../Shimmer/ShimmerLandingpage.jsx";
import { Icon } from "../components/Component.js";



const PodcastLandingpage = () => {
  const navigate = useNavigate();

  // const gotodetailpage = (id) => {
  //   const selectedCard = podcastcard.find(card => card.id === id);
  //   navigate('/podcast-details', { state: selectedCard });
  //   // navigate(`/podcast-details-blog/${id}`);
  //   // navigate("/podcast-details");
  // };
  const gotodetailpage = (id) => {
    navigate(`/episodes/${id}`);
  };
  // const gotoartistpage = (id) => {
  //   const selectedArtist = Artistdetails.find(artist => artist.id === id);
  //   navigate('/artist', { state: selectedArtist });
  //   // navigate(`/artist`);
  // }
  const gotoaddpodcast = () => {
    navigate(`/add-podcast`);
  };
  const gotoupdatepodcast = () => {
    navigate(`/update-podcast/$(id)`);
  };

  const settings = {
    dots: false,
    arrows: true,
    autoplay: false,
    cssEase: "linear",
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const { data: prodcastData, error: prodcastIsError, isLoading: prodcastIsLoading } = useQuery({
    queryKey: ['podcasts'],
    queryFn: fetchPodcasts,
    gcTime: 0,
    staleTime: Infinity,
  });


  if (prodcastIsLoading) return <ShimmerLandingpage />
  if (prodcastIsError) return <div>Error: </div>;


  return (
    <>
      <section className="section-podcaste">
        <div className="container-fluid bg-white p-0">
          {/* <h3 className="text-center fw-normal sub-head-text">Podcasts</h3> */}
          {/* <div className=" px-2 py-3" onClick={gotoupdatepodcast}>
            <button className="btn btn-sm btn-danger d-none d-sm-block">Update Podcast</button>
            <button className="btn btn-sm btn-danger d-md-none">+</button>
          </div> */}
          <div>
            <div className="rounded-2 p-3 allpodcast">
              {/* <div className="add-podcast-button px-2" onClick={gotoaddpodcast}>
                <button className="btn btn-sm btn-danger d-none d-sm-block">+ Add Podcast</button>
                <button className="btn btn-sm btn-danger d-md-none">+</button>
              </div> */}

              <Tabs
                defaultActiveKey="All"
                id="uncontrolled-tab-example"
                className="mb-3 border-0 custom-tabs "
              >
                <Tab eventKey="All" title="All" className="Tab-container custom-scrollbar pb-5 mb-5">
                  <h5 className="">Newly Added</h5>


                  <Slider {...settings}>
                    {prodcastData?.data?.newly_added?.map((podvalue, index) => (
                      <div className="py-1 px-2 " key={podvalue.id}>
                        <div
                          className="card h-250px rounded-3 border-0 pointer dark-mode-card"
                          onClick={() => gotodetailpage(podvalue.id)}
                        >
                          <div className="position-relative card-opacity">
                            <div className="card-play-button">
                              <Icon name="play-circle" className="fs-35px img-hover-icon"></Icon>
                            </div>
                            <img
                              src={podvalue.image_url}
                              alt=""
                              className="img-responsive rounded-3"
                            />
                          </div>
                          <div className="card-body p-0 px-1 py-1">
                            <h6 className=" fs-7 lato-font truncated-text fw-bold">{podvalue.title}</h6>
                            {/* <h6 className=" fs-7 truncated-text lato-font">{podvalue.description}</h6> */}
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                  <h6 className="py-2">Popular</h6>
                  <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-3">
                    {prodcastData?.data?.popular?.map((podvalue, index) => (
                      <div className="py-2" key={podvalue.id}>
                        <div className="">
                          <div
                            className="card h-100 rounded-3 border-0 pointer hover-card"
                            onClick={() => gotodetailpage(podvalue.id)}
                          >
                            <div>
                              <img
                                src={podvalue.image_url}
                                alt=""
                                className="w-100 rounded-3 "
                                style={{ height: '200px', objectFit: 'cover' }}
                              />
                            </div>
                            <div className="card-body p-0 px-1 py-2">
                              <h6 className="pt-1 fs-7 lato-font fw-bold">{podvalue.title}</h6>
                              <h6 className=" fs-7 truncated-text-popular lato-font">{podvalue.description}</h6>
                              <div className="d-flex justify-content-between  m-0">
                                {/* <p>
                                  Aug 23
                                  .2 min
                                </p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <h5 className="py-2 ">Artist</h5>
                  <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-6">
                    {Artistdetails.map((artist, index) => (
                      <div className="py-2 text-center hover-card-artist rounded-3 pointer" key={artist.id}
                      //  onClick={gotoartistpage}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <img
                            src={artist.imgsrc}
                            alt=""
                            className="rounded-circle"
                            style={{ objectFit: 'cover' }}
                          />
                        </div>
                        <h6 className="pt-2">{artist.title}</h6>
                      </div>
                    ))}
                  </div>


                </Tab>
                <Tab eventKey="Historical" title="Historical" className="Tab-container custom-scrollbar">
                  <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-3 py-2">
                    {prodcastData?.data?.popular?.map((podvalue, index) => (
                      <div className="py-2" key={podvalue.id}>
                        <div className="">
                          <div
                            className="card h-100 rounded-3 border-0 pointer hover-card"
                            onClick={() => gotodetailpage(podvalue.id)}
                          >
                            <div>
                              <img
                                src={podvalue.image_url}
                                alt=""
                                className="w-100 rounded-3 "
                                style={{ height: '200px', objectFit: 'cover' }}
                              />
                            </div>
                            <div className="card-body p-0 px-1 py-2">
                              <h6 className="pt-1 fs-7 lato-font fw-bold">{podvalue.title}</h6>
                              <h6 className=" fs-7 truncated-text-popular lato-font">{podvalue.description}</h6>
                              <div className="d-flex justify-content-between  m-0">
                                {/* <p>
                                  Aug 23
                                  .2 min
                                </p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Tab>
                <Tab eventKey="Motivation" title="Motivation" className="Tab-container custom-scrollbar">
                  <Slider {...settings}>
                    {prodcastData?.data?.newly_added?.map((podvalue, index) => (
                      <div className="py-1 px-2 " key={podvalue.id}>
                        <div
                          className="card h-250px rounded-3 border-0 pointer dark-mode-card"
                          onClick={() => gotodetailpage(podvalue.id)}
                        >
                          <div className="position-relative card-opacity">
                            <div className="card-play-button">
                              <Icon name="play-circle" className="fs-35px img-hover-icon"></Icon>
                            </div>
                            <img
                              src={podvalue.image_url}
                              alt=""
                              className="img-responsive rounded-3"
                            />
                          </div>
                          <div className="card-body p-0 px-1 py-1">
                            <h6 className=" fs-7 lato-font truncated-text fw-bold">{podvalue.title}</h6>
                            {/* <h6 className=" fs-7 truncated-text lato-font">{podvalue.description}</h6> */}
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </Tab>


              </Tabs>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PodcastLandingpage;
