import React, { useEffect, useState } from "react";
// import { createCommonContext } from "./createCardContext";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Icon } from "../components/Component";
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { deleteEpisode, fetchPodcasts } from "../http/Post";
import { formatDate, formatDuration } from "../utils/CommonFunctions";
import Shimmerpodcastdetails from "../Shimmer/Shimmerpodcastdetails";
import { useAudio } from "./AudioProvider";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { queryClient } from "../App";

const Podcastdetails = () => {
    const { id } = useParams();
    const { profile } = useParams();
    const location = useLocation();
    const { imgsrc, title, description } = location.state || {};
    //   const { setPodcastFooter, setCloseIcon, setSelectedEpisode } =
    //     useContext(createCommonContext);

    // useEffect(() => {
    //     setPodcastFooter(true);
    //     setCloseIcon(false);
    // }, [setPodcastFooter, setCloseIcon]);

    //   const handleEpisodeClick = (episode) => {
    //     const episodeString = JSON.stringify(episode);
    //     localStorage.setItem("EpisodeToPlay", episodeString);
    //     setSelectedEpisode(episode); // Update the context
    //     // console.log("Stored episodeString:", episodeString);
    //   };


    const [isFollowed, setIsFollowed] = useState(false);
    const [isPlay, setIsPlay] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const navigate = useNavigate();
    const [gradient, setGradient] = useState("");

    const { setAudioData } = useAudio(); // Access the setter from context

    console.log("ID :", id);

    // const { data: prodcastData, error: prodcastIsError, isLoading: prodcastIsLoading } = useQuery(['episodes', id], () => fetchPodcasts(id));
    const { data: podcastData, isLoading: prodcastIsLoading, isError: prodcastIsError, refetch: prodcastDataRefetch } = useQuery({
        queryKey: ["episodes", id],
        queryFn: async () => fetchPodcasts({ type: 1, id: id }),
        gcTime: 0,
        staleTime: Infinity,
    });
    // console.log("ProadCastData:", podcastData);
    // const podcast_episodes = data.episodes; // Adjust based on your API response structure

    const toggleFollow = () => {
        setIsFollowed(!isFollowed);
    };
    const toggleplay = () => {
        setIsPlay(!isPlay);
    };
    const gotoaddepisode = (id) => {
        navigate(`/add-episode/${id}`)
    }
    const gotoupdateepisode = (id) => {
        navigate(`/update-episode/${id}`)
    }

    console.log(location.pathname === "/profile/episodes/68");
    const currentUrl = window.location.href;
    const shareFacebookUrl = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(
        currentUrl
    )}`;

    const instagramProfileUrl = "https://www.instagram.com/holisticwellnessbty/";

    const shareWhatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
        currentUrl
    )}`;
    // useEffect(() => {
    //     if (podcastData) {
    //         const audiourl = podcastData?.data?.episodes?.map((item) => {
    //             return item.audio_url
    //         })
    //         // console.log("Adio balu", audiourl);
    //         // Assuming podcastData contains an audiourl field
    //         // const { audiourl } = podcastData;
    //         setAudioUrl(audiourl); // Update the context
    //     }
    // }, [podcastData, setAudioUrl]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const handleShare = (id) => {
        if (navigator.share) {
            navigator
                .share({
                    title: "Podcast",
                    text: "Check out that podcast",
                    url: location.pathname,
                })
                .then(() => console.log("Successful share", id))
                .catch((error) => console.log("Error sharing:", error));
        } else {
            // console.log("Share not supported on this browser");
        }
    };

    const handleEpisodeClick = (id) => {
        if (podcastData) {
            podcastData?.data?.episodes?.filter((item) => {
                if (item.id === id) {
                    setAudioData({
                        audioUrl: item.audio_url,
                        image: item.image_url,  // Use item.image if available, otherwise fallback to imgsrc
                        name: item.title      // Use item.name if available, otherwise fallback to title
                    });
                    // console.log("Audio URL:", item.audio_url);

                }
            })
            // Assuming podcastData contains an audiourl field
            // const { audiourl } = podcastData;
            // setAudioUrl(audiourl); // Update the context
        }
    }


    // Function to generate random color
    const getRandomColor = () => {
        const letters = "0123456789ABCDEF";
        let color = "#";
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    // Function to generate a random gradient
    const getRandomGradient = () => {
        const randomColor1 = getRandomColor();
        const whiteColor = "#FFFFFF"; // Second color is always white
        return `linear-gradient(${randomColor1}, ${whiteColor})`;
    };

    // Apply random gradient when the component mounts (or page loads)
    useEffect(() => {
        setGradient(getRandomGradient());
    }, []);


    const { mutate: deleteepisodemutate, reset: deleteepisodereset } = useMutation({
        mutationKey: ["delete-episode"],
        mutationFn: deleteEpisode,
        onSuccess: (data) => {
            // console.log(data)
            if (data?.status) {
                toast.success(data.message);
                queryClient.invalidateQueries(["userpodcasts"]);
            } else {
                toast.error(data?.message);
            }
            deleteepisodereset();
        },
        onError: (error) => {
            toast.error(`${error.message}`);
        },
    });

    const deleteepisode = async (id) => {
        // console.log(`deleting ${id}`);
        Swal.fire({
            icon: "warning",
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel!",
            focusConfirm: false,
        }).then(async (result) => {
            if (result.isConfirmed) {
                // Proceed with deletion if confirmed
                await deleteepisodemutate(id);
                Swal.fire(
                    "Deleted!",
                    "Your podcast has been deleted.",
                    "success"
                );
            }

        });
    };

    if (prodcastIsLoading) return <Shimmerpodcastdetails />;
    if (prodcastIsError) return <div>Error: </div>;

    console.log(podcastData)

    return (
        <>
            <section className="podcast-details ">

                <div >
                    {isVisible && (
                        <div className={`scroll-div podacst-detail-background  shadow-sm ${isVisible ? 'show' : ''}`}
                        // style={{
                        //     background: gradient,
                        // }}
                        >
                            <div className="row justify-content-md-center align-content-center">
                                <div className="col-8 d-flex" >
                                    <img
                                        src={podcastData.data.podcast_data.image_url}
                                        alt=""
                                        className=" rounded-3"
                                        height={"60px"}
                                    />
                                    <div>
                                        <h3 className="fw-bolder px-4 letter-spacing-1">{podcastData.data.podcast_data.title}</h3>
                                        <h6 className=" px-4 ">{podcastData.data.podcast_data.language_name}</h6>
                                    </div>
                                </div>
                                <div className="col-4 d-flex justify-content-center py-2">
                                    <div className="px-2">
                                        <button
                                            className="btn  px-3 bg-white  text-danger "
                                            onClick={toggleFollow}
                                        >
                                            {isFollowed ? <Icon name="check"></Icon> : <Icon name="plus-sm"></Icon>}
                                        </button>
                                    </div>
                                    <div className="">

                                        <button
                                            className="btn py-1 px-3 text-white bg-danger "
                                            onClick={toggleplay}
                                        >
                                            {isPlay ? "Pause" : "Play"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div>



                </div>
                <div className="container-fluid bg-white pb-5">

                    <div className="row  p-3 mb-3  podacst-detail-background"
                    // style={{
                    //     background: gradient,
                    // }}
                    >
                        <div className="col-md-4 col-lg-3  text-center">

                            <img
                                src={podcastData.data.podcast_data.image_url}
                                alt=""
                                className="rounded-3 w-100 podcastdetail-profileimg"
                                height={"250px"}
                            />

                        </div>
                        <div className="col-md-8 col-lg-9 py-3 py-md-0">
                            <div>
                                <h4 className="fw-bolder">{podcastData.data.podcast_data.title}</h4>
                                <p className="text-breal">{podcastData.data.podcast_data.description}</p>



                                <p>
                                    {podcastData?.data?.podcast_data?.tags?.map((tag, index) => (
                                        <span key={index}>#{tag} </span>
                                    ))}
                                </p>
                                <p>
                                    {podcastData.data.podcast_data.language_name}
                                </p>
                                <p>{podcastData.data.podcast_data.category_name}</p>
                                <div className="d-flex gap-4 justify-content-center justify-content-md-start ">
                                    <div className="">
                                        <button
                                            className="btn  px-3 bg-white  text-danger "
                                            onClick={toggleFollow}>

                                            {isFollowed ? <Icon name="check"></Icon> : <Icon name="plus-sm"></Icon>}
                                        </button>
                                    </div>
                                    <div>
                                        <button
                                            className="btn text-white bg-danger "
                                            onClick={toggleplay}
                                        >
                                            {isPlay ? "Pause" : "Play"}
                                        </button>
                                    </div>
                                    <div className="dropend sharepodcast">

                                        <UncontrolledDropdown>
                                            <DropdownToggle className="dropdown-toggle btn btn-icon btn-light bg-transparent border-0 hover-black">
                                                <Icon name="more-v"></Icon>
                                                <DropdownMenu className='p-0'>
                                                    <ul className="link-list-opt no-bdr p-0">
                                                        <li>
                                                            <DropdownItem onClick={() => handleShare(id)}>
                                                                <Icon name="share-fill"></Icon>
                                                                <span className="">Share</span>
                                                            </DropdownItem>
                                                        </li>
                                                        {/* <li>
                                                            <DropdownItem >
                                                                <Icon name="trash"></Icon>
                                                                <span>Delete</span>
                                                            </DropdownItem>
                                                        </li> */}

                                                    </ul>
                                                </DropdownMenu>
                                            </DropdownToggle>

                                        </UncontrolledDropdown>
                                    </div>
                                </div>
                            </div>
                        </div >
                        {location.pathname.includes("/profile/episodes") && (
                            <div className="py-1 text-end">
                                <button className="btn btn-danger" onClick={() => gotoaddepisode(id)}>
                                    + Add Episode
                                </button>
                            </div>
                        )}
                    </div >
                    <div className="mb-3 poscast-episode-list table-responsive">
                        <table className="table bg-dark">
                            <thead>
                                <tr>
                                    <th scope="col" className=" text-danger">Episode</th>
                                    <th scope="col" className=" text-danger">Uploaded</th>
                                    <th scope="col" className="text-danger ">Duration</th>
                                    <th scope="col" className="text-danger"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {podcastData?.data?.episodes?.length > 0 ? (
                                    podcastData.data.episodes.map((episode) => (

                                        <tr key={episode.id}
                                            className="pointer border-bottom episode-body hover-apply"
                                        >
                                            <td onClick={() => handleEpisodeClick(episode.id)} className="">

                                                <div className="position-relative  d-flex align-items-center">
                                                    <img src={episode.image_url} alt="" className="img-fluid pe-2" width={"50px"} height={"50px"} />
                                                    <button className="play-button">
                                                        <Icon name="play-circle-fill" className="text-dark" />
                                                    </button>
                                                    <div>
                                                        <p className="mb-0">{episode.title}</p>
                                                        <span className="truncated-text-popular-episodes">
                                                            {episode.description}
                                                        </span>
                                                    </div>
                                                </div>


                                            </td>
                                            <td onClick={() => handleEpisodeClick(episode.id)}>{formatDate(episode.published_at)}</td>
                                            <td className="" onClick={() => handleEpisodeClick(episode.id)}>{formatDuration(episode.duration)}</td>
                                            <td>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle className="dropdown-toggle btn btn-icon btn-light bg-transparent border-0 hover-black">
                                                        <Icon name="more-v"></Icon>
                                                        <DropdownMenu className='p-0'>
                                                            <ul className="link-list-opt no-bdr">
                                                                <li>
                                                                    <DropdownItem
                                                                        onClick={() => gotoupdateepisode(episode.id)}
                                                                    >
                                                                        <Icon name="edit"></Icon>
                                                                        <span>Edit</span>
                                                                    </DropdownItem>
                                                                </li>
                                                                <li>
                                                                    <DropdownItem
                                                                        onClick={() => deleteepisode(episode.id)}
                                                                    >
                                                                        <Icon name="trash"></Icon>
                                                                        <span>Delete</span>
                                                                    </DropdownItem>
                                                                </li>

                                                            </ul>
                                                        </DropdownMenu>
                                                    </DropdownToggle>
                                                </UncontrolledDropdown>
                                            </td>

                                        </tr>
                                    ))
                                ) : (
                                    <tr className="">
                                        <td></td>
                                        <td className="text-center">
                                            No episode found
                                        </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}

                            </tbody>

                        </table>


                    </div>

                </div >




            </section >
        </>
    );
};

export default Podcastdetails;
