import podcastimg from "../images/Podcast/Landingpage/podcast-3.jpg"
import podcastimg2 from "../images/Podcast/Landingpage/podcast-5.jpg"
import podcastimg3 from "../images/Podcast/Landingpage/podcast-6.jpg"
import podcastimg4 from "../images/Podcast/Landingpage/podcast-7.jpg"
import podcastimg5 from "../images/Podcast/Landingpage/podcast-8.jpg"
import podcastimg6 from "../images/Podcast/Landingpage/podcast-1.jpg"
import artist from "../images/Podcast/Landingpage/artist-1.jpg"
import artist2 from "../images/Podcast/Landingpage/artist-2.jpg"
import artist3 from "../images/Podcast/Landingpage/artist-3.jpg"
import artist4 from "../images/Podcast/Landingpage/artist-4.jpg"
import artist5 from "../images/Podcast/Landingpage/artist-5.jpg"
import artist6 from "../images/Podcast/Landingpage/artist-6.jpg"
export const episodes = [
  {
    id: 1,
    title: "Episode-1 slkdmc",
    imageSrc: podcastimg,
    badgeText: "Premium",
    uploadedOn: "Dec 2023",
    duration: "01:00",
    audioSrc: "meetchef.mp3",
    musicname: "Meet Chef in Heelz",
  },
  {
    id: 2,
    title: "Episode-2",
    imageSrc: podcastimg,
    badgeText: "",
    uploadedOn: "Dec 2023",
    duration: "01:00",
    audioSrc: "african-soap.mp3",
    musicname: "African Raw Black Soap",
  },
  {
    id: 3,
    title: "Episode-3",
    imageSrc: podcastimg,
    badgeText: "",
    uploadedOn: "Dec 2023",
    duration: "01:00",
    audioSrc: "meetmike.mp3",
    musicname:
      "Meet Mike and Crystal, the husband and wife team on a life-long health journey together!",
  },
  {
    id: 4,
    title: "Episode-4",
    imageSrc: podcastimg,
    badgeText: "",
    uploadedOn: "Dec 2023",
    duration: "01:00",
    audioSrc: "meetmike.mp3",
    musicname:
      "Meet Mike and Crystal, the husband and wife team on a life-long health journey together!",
  },
  {
    id: 5,
    title: "Episode-5",
    imageSrc: podcastimg,
    badgeText: "",
    uploadedOn: "Dec 2023",
    duration: "01:00",
    audioSrc: "meetmike.mp3",
    musicname:
      "Meet Mike and Crystal, the husband and wife team on a life-long health journey together!",
  },
  {
    id: 6,
    title: "Episode-6",
    imageSrc: podcastimg,
    badgeText: "",
    uploadedOn: "Dec 2023",
    duration: "01:00",
    audioSrc: "meetmike.mp3",
    musicname:
      "Meet Mike and Crystal, the husband and wife team on a life-long health journey together!",
  },
  {
    id: 7,
    title: "Episode-7",
    imageSrc: podcastimg,
    badgeText: "",
    uploadedOn: "Dec 2023",
    duration: "01:00",
    audioSrc: "meetmike.mp3",
    musicname:
      "Meet Mike and Crystal, the husband and wife team on a life-long health journey together!",
  },
];

export const podcastcard = [
  {
    id: 1,
    imgsrc: podcastimg,
    title: "Meet Chef in Heelz",
    description:
      " Ashonti Harris is a passionate chef and natural herb user on a  mission to educate young people on the importance of healthy    eating and cultivating healthy habits in their family. With Chef in Heelz, you can learn how to grow the food you need to thrive and make healthy eating a part of your lifestyle. Let's make healthy eating a priority and join Chef in Heelz on this journey!",
  },
  {
    id: 2,
    imgsrc: podcastimg2,
    title: "African Raw Black Soap",
    description:
      " Ashonti Harris is a passionate chef and natural herb user on a  mission to educate young people on the importance of healthy    eating and cultivating healthy habits in their family. With Chef in Heelz, you can learn how to grow the food you need to thrive and make healthy eating a part of your lifestyle. Let's make healthy eating a priority and join Chef in Heelz on this journey!",
  },
  {
    id: 3,
    imgsrc: podcastimg3,
    title: "Meet Mike and Crystal",
    description:
      " Ashonti Harris is a passionate chef and natural herb user on a  mission to educate young people on the importance of healthy    eating and cultivating healthy habits in their family. With Chef in Heelz, you can learn how to grow the food you need to thrive and make healthy eating a part of your lifestyle. Let's make healthy eating a priority and join Chef in Heelz on this journey!",
  },
  {
    id: 4,
    imgsrc: podcastimg4,
    title: "Meet Chef in Heelz",
    description:
      " Ashonti Harris is a passionate chef and natural herb user on a  mission to educate young people on the importance of healthy    eating and cultivating healthy habits in their family. With Chef in Heelz, you can learn how to grow the food you need to thrive and make healthy eating a part of your lifestyle. Let's make healthy eating a priority and join Chef in Heelz on this journey!",
  },
  {
    id: 5,
    imgsrc: podcastimg5,
    title: "African Raw Black Soap",
    description:
      " Ashonti Harris is a passionate chef and natural herb user on a  mission to educate young people on the importance of healthy    eating and cultivating healthy habits in their family. With Chef in Heelz, you can learn how to grow the food you need to thrive and make healthy eating a part of your lifestyle. Let's make healthy eating a priority and join Chef in Heelz on this journey!",
  },
  {
    id: 6,
    imgsrc: podcastimg6,
    title: "African Raw Black Soap",
    description:
      " Ashonti Harris is a passionate chef and natural herb user on a  mission to educate young people on the importance of healthy    eating and cultivating healthy habits in their family. With Chef in Heelz, you can learn how to grow the food you need to thrive and make healthy eating a part of your lifestyle. Let's make healthy eating a priority and join Chef in Heelz on this journey!",
  },
  {
    id: 7,
    imgsrc: podcastimg4,
    title: "African Raw Black Soap",
    description:
      " Ashonti Harris is a passionate chef and natural herb user on a  mission to educate young people on the importance of healthy    eating and cultivating healthy habits in their family. With Chef in Heelz, you can learn how to grow the food you need to thrive and make healthy eating a part of your lifestyle. Let's make healthy eating a priority and join Chef in Heelz on this journey!",
  },
  {
    id: 8,
    imgsrc: podcastimg2,
    title: "African Raw Black Soap",
    description:
      " Ashonti Harris is a passionate chef and natural herb user on a  mission to educate young people on the importance of healthy    eating and cultivating healthy habits in their family. With Chef in Heelz, you can learn how to grow the food you need to thrive and make healthy eating a part of your lifestyle. Let's make healthy eating a priority and join Chef in Heelz on this journey!",
  },
  {
    id: 9,
    imgsrc: podcastimg,
    title: "African Raw Black Soap",
    description:
      " Ashonti Harris is a passionate chef and natural herb user on a  mission to educate young people on the importance of healthy    eating and cultivating healthy habits in their family. With Chef in Heelz, you can learn how to grow the food you need to thrive and make healthy eating a part of your lifestyle. Let's make healthy eating a priority and join Chef in Heelz on this journey!",
  },

];
export const Artistdetails = [
  {
    id: 1,
    imgsrc: artist,
    title: "Zayn Malik",
  },
  {
    id: 2,
    imgsrc: artist2,
    title: "Alen Walker",

  },
  {
    id: 3,
    imgsrc: artist3,
    title: "Katy Perry",

  },
  {
    id: 4,
    imgsrc: artist4,
    title: "Selena Gomez",
  },
  {
    id: 5,
    imgsrc: artist5,
    title: "Taylor Swift",

  },
  {
    id: 6,
    imgsrc: artist6,
    title: "ED Sheeren",

  },

];
export const cutWord = (remain, text) => {
  return text.substring(0, remain) + (text.length > 10 ? "..." : "")
}

