import React, { useEffect, useRef, useState } from "react";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { Icon } from "../components/Component";
import { useAudio } from "./AudioProvider";

const AudioPlayerMusic = ({ songTitle, songSrc, songImg }) => {
  const audioRef = useRef(null);
  const imgRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [rotationAngle, setRotationAngle] = useState(0);
  const { audioData } = useAudio(); // Access the audioUrl from context
  const [duration, setDuration] = useState("00:00"); // State to hold the audio duration

  useEffect(() => {
    if (isPlaying) {
      const startRotation = performance.now();

      const rotate = (timestamp) => {
        if (!imgRef.current) return;

        const elapsed = timestamp - startRotation;
        const newRotationAngle = (rotationAngle + (elapsed / 1000) * 72) % 360; // 72deg per second (adjust based on your rotation speed)
        imgRef.current.style.transform = `rotate(${newRotationAngle}deg)`;

        if (isPlaying) {
          requestAnimationFrame(rotate);
        } else {
          setRotationAngle(newRotationAngle);
        }
      };

      requestAnimationFrame(rotate);
    }
  }, [isPlaying, rotationAngle]);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  const handleLoadedMetadata = () => {
    const audioElement = audioRef.current.audio.current;
    if (audioElement) {
      setDuration(audioElement.duration); // Set the duration when the metadata is loaded
    }
  };

  // Helper function to format the duration into minutes and seconds
  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  return (
    <section className="audio-player bottom-10">
      <AudioPlayer
        ref={audioRef}
        controls
        src={audioData.audioUrl}
        customIcons={{
          play: <em className="icon ni ni-play-circle-fill text-dark fs-1"></em>,
          pause: <em className="icon ni ni-pause-circle-fill text-dark fs-1"></em>,
          rewind: <em className="icon ni ni-rewind-fill text-dark fs-3"></em>,
          forward: <em className="icon ni ni-forward-fill text-dark fs-3"></em>,
          volume: <Icon name="vol " className="text-dark" />,
          volumeMute: <Icon name="vol-off" className="text-danger" />,
        }}
        customAdditionalControls={[
          RHAP_UI.LOOP,
          <>
            {audioData ? (
              <div className="current-song-play d-flex align-items-center">
                <img
                  ref={imgRef}
                  src={audioData.image}
                  className={`img-fluid border-radius-50 px-1 ${isPlaying ? 'rotating' : ''}`}
                  width={"50px"}
                  height={"50px"}
                  alt="song-thumbnail"
                />
                <p className="text-dark">Now Playing: {audioData.name}</p>
              </div>
            ) : (
              <p>No audio available</p>
            )}
          </>,
        ]}
        onPlay={handlePlay}
        onPause={handlePause}
        onLoadedMetadata={handleLoadedMetadata} // Event to get the duration
      />
      {/* <div className="audio-duration">
        {duration > 0 && <p>Duration: {formatDuration(duration)}</p>}
      </div> */}
    </section>
  );
};

export default AudioPlayerMusic;
