import React, { createContext, useContext, useState } from "react";

// Create the context
const AudioContext = createContext();

// Create a provider component
export const AudioProvider = ({ children }) => {
  const [audioData, setAudioData] = useState({
    audioUrl: "",
    image: "",
    name: "",
  });
  return (
    <AudioContext.Provider value={{ audioData, setAudioData }}>
      {children}
    </AudioContext.Provider>
  );
};

// Create a custom hook for easier access
export const useAudio = () => useContext(AudioContext);
