import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { createCommonContext } from "./createCardContext";
import { episodes } from "./Podcastdata";
import { Artist } from "./Podcastdata";
import podcastimg from "../images/Podcast/Landingpage/podcast-1.jpg"
import { BsThreeDotsVertical } from "react-icons/bs";
import { useLocation } from 'react-router-dom';
import { FaPlay } from "react-icons/fa";
import { Icon } from "../components/Component";

function Artistdetails() {
    const location = useLocation();
    const Artist = location.state || {};
    // const { imgsrc, title, description } = location.state || {};
    const [isFollowed, setIsFollowed] = useState(false);
    const [isPlay, setIsPlay] = useState(false);
    const [isVisible, setIsVisible] = useState(false);


    const toggleFollow = () => {
        setIsFollowed(!isFollowed);
    };
    const toggleplay = () => {
        setIsPlay(!isPlay);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const currentUrl = window.location.href;
    return (
        <>
            <section className="podcast-details ">
                <div >
                    {isVisible && (
                        <div className={`scroll-div podacst-detail-background shadow-sm ${isVisible ? 'show' : ''}`}>
                            <div className="row justify-content-md-center">
                                <div className="col-8 d-flex " >
                                    <img
                                        src={Artist.imgsrc}
                                        alt=""
                                        className=" rounded-3"
                                        height={"60px"}
                                    />
                                    <h2 className="fw-bolder px-4 letter-spacing-1">{Artist.title}</h2>
                                </div>
                                <div className="col-4 d-flex justify-content-end">
                                    <div>
                                        <button
                                            className="btn py-1 px-3 text-white bg-danger rounded-5"
                                            onClick={toggleplay}
                                        >
                                            {isPlay ? "Play" : "Pause"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="container-fluid bg-white pb-5">

                    <div className="row  p-3 mb-3 podacst-detail-background">
                        <div className="col-md-3">
                            <div>
                                <img
                                    src={Artist.imgsrc}
                                    alt=""
                                    className="w-100 rounded-3"
                                />
                            </div>
                        </div>
                        <div className="col-md-9 py-3 py-md-0">
                            <div>
                                <h4 className="fw-bolder">{Artist.title}</h4>
                                <p>{Artist.description}</p>
                                <p>
                                    #DrCrystal #ChefinHeelz #HealthyEating #HealthyHabits
                                    #HolisticWellnessBeauty #alkalineherbs #lifestyle{" "}
                                </p>
                                <div className="d-flex gap-4 justify-content-center justify-content-md-start ">
                                    <div className="">
                                        <button
                                            className="btn  px-3 bg-white  text-danger border-danger rounded-5"
                                            onClick={toggleFollow}
                                        >
                                            {isFollowed ? "Followed" : "Follow"}
                                        </button>
                                    </div>
                                    <div>
                                        <button
                                            className="btn text-white bg-danger rounded-5"
                                            onClick={toggleplay}
                                        >
                                            {isPlay ? "Play" : "Pause"}
                                        </button>
                                    </div>
                                    <div className=" dropend sharepodcast">
                                        <button
                                            type="button"
                                            className=" bg-transparent border-0 "
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            {/* <i className="bx bx-dots-vertical-rounded"></i> */}
                                            <BsThreeDotsVertical />
                                        </button>
                                        {/* <div className="dropdown">
                                            <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton"
                                            >
                                                <li>
                                                    <Link
                                                        className="dropdown-item dropdown-toggle"
                                                        to="#"
                                                        id="shareDropdown"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        <i className="bx bx-share-alt"></i>
                                                        <span className="ps-2">Share</span>
                                                    </Link>
                                                    <ul className="dropdown-menu dropdown-submenu">
                                                        <li>
                                                            <Link
                                                                className="dropdown-item"
                                                                to={shareFacebookUrl}
                                                            >
                                                                <i className="bx bxl-facebook"></i>
                                                                <span className="ps-2">Facebook</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link
                                                                className="dropdown-item"
                                                                to={instagramProfileUrl}
                                                            >
                                                                <i className="bx bxl-whatsapp"></i>
                                                                <span className="ps-2">Whatsapp</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link
                                                                className="dropdown-item"
                                                                to={shareWhatsappUrl}
                                                            >
                                                                <i className="bx bxl-instagram"></i>
                                                                <span className="ps-2">Instagram</span>
                                                            </Link>
                                                        </li>
                                             <li>
                              <Link className="dropdown-item" to="">
                                <i className="bx bxs-playlist"></i>
                                <span className="ps-2">LinkdIn</span>
                              </Link>
                                         </li>   
                                                    </ul>
                                                </li>
                                                <li>
                                                    <Link className="dropdown-item" to="">
                                                        <i className="bx bxs-playlist"></i>
                                                        <span className="ps-2">Add to Playlist</span>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                        download="music.zip"
                                                    >
                                                        <i className="bx bxs-download"></i>
                                                        <span className="ps-2">Download</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div >
                    <div className="mb-3 poscast-episode-list p-3 ">
                        <table className="w-100">
                            <thead>
                                <tr className="border-bottom">
                                    <th className=" text-danger">Songs</th>
                                    <th className=" text-danger ">Uploaded On</th>
                                    <th className=" text-danger ">Duration</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                {episodes.map((episode, index) => (
                                    <tr
                                        key={episode.id}
                                        className="pointer border-bottom episode-body"
                                    // onClick={() => handleEpisodeClick(episode)}
                                    >
                                        <td>
                                            <div className="d-flex flex-wrap align-items-center episode-row">
                                                <div className="position-relative">
                                                    <img src={Artist.imgsrc} alt="" height="30" className="pe-3" />
                                                    <button className="play-button">
                                                        <Icon name="play-circle-fill " className="text-dark" />
                                                    </button>
                                                </div>
                                                <div>
                                                    <p className="mb-0">{episode.title}</p>
                                                    <p className="mb-0 fs-7">{episode.musicname}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="">{episode.uploadedOn}</td>
                                        <td className="">{episode.duration}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div >
            </section >
        </>
    )
}

export default Artistdetails
