import React, { useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import LayoutSidebar from "../layout/index-sidebar";
import PodcastHome from "../pages/PodcastHome";
import PodcastLandingpage from "../Podcast-pages/PodcastLandingpage";
// import Podcastdetails from "../Podcast-pages/Podcastdetails";
import AudioPlayerMusic from "../Podcast-pages/AudioPlayerMusic";
import Artistdetails from "../Podcast-pages/Artistdetails";
import Podcastdetails from "../Podcast-pages/Podcastdetails";
import AddPodcast from "../Podcast-pages/add-podcast/Addpodcast";
import Podcastprofile from "../Podcast-pages/Podcast-profile/Podcastprofile";
import UpdatePodcastnew from "../Podcast-pages/add-podcast/Updatenewcode";
import Addepisode from "../Podcast-pages/Podcast-Episode/Addepisode";
import RandomGradientDiv from "../Podcast-pages/Podcast-Episode/Demo";
import Updateepisode from "../Podcast-pages/Podcast-Episode/Updateepisode";

const Pages = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <Routes>
      <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutSidebar />}>
        <Route index element={<PodcastHome />} />
        <Route path="profile/episodes/:id" element={<Podcastdetails />} />
        <Route path="/episodes/:id" element={<Podcastdetails />} />
        <Route path="/add-episode/:episodeid" element={<Addepisode />} />
        <Route path="/update-episode/:episodeid" element={<Updateepisode />} />
        <Route path="/artist" element={<Artistdetails />} />
        <Route path="/add-podcast" element={<AddPodcast />} />
        <Route path="/update-podcast/:id" element={<UpdatePodcastnew />} />
        <Route path="/profile" element={<Podcastprofile />} />
        <Route path="/demo" element={<RandomGradientDiv />} />

        <Route path="/" element={<AudioPlayerMusic />} />

      </Route>
    </Routes>
  );
};
export default Pages;
